import { MutableRefObject, useEffect } from 'react'
import {
  HeaderNavItemType,
  NavBarActiveKey,
} from 'app.layout/constant/pageLayout.type'

/**
 * @description 모바일 nav 리스트의 뒤쪽 탭이 활성화 될 경우 활성화 된 탭을 화면에 표시합니다
 * @param parentRef - 스크롤 효과를 적용할 부모 요소의 ref
 * @param navItems - 스크롤 효과를 적용할 요소들의 목록
 * @param targetTab - 현재 활성화된 탭
 *  */
interface UseNavTabScrollIntoViewProps {
  parentRef: MutableRefObject<HTMLAnchorElement[] | null[]>
  navItems: HeaderNavItemType[]
  targetTab: NavBarActiveKey
}

export const useNavTabScrollIntoView = ({
  parentRef,
  navItems,
  targetTab,
}: UseNavTabScrollIntoViewProps) => {
  useEffect(() => {
    navItems.forEach((menuItem, index) => {
      const isActiveTab = targetTab === menuItem.active

      if (isActiveTab) {
        parentRef.current[index]?.scrollIntoView({
          inline: 'start',
        })
      } else {
        // 2depth가 1depth와 다른  active key인 경우 scrollIntoView 적용시 y 스크롤도 함께 움직여 ClinetRe
        menuItem.menuItems?.forEach((childrenTabItem) => {
          const isActiveChildrenTab = targetTab === childrenTabItem.active
          if (isActiveChildrenTab) {
            const parentElement = parentRef.current[index]?.parentElement
            const childElement = parentRef.current[index]

            if (parentElement && childElement) {
              const childRect = childElement.getBoundingClientRect()
              const parentRect = parentElement.getBoundingClientRect()

              const offset = 20
              const scrollPosition =
                parentElement.scrollLeft +
                (childRect.left - parentRect.left) -
                offset

              parentElement.scrollTo({
                left: scrollPosition,
              })
            }
          }
        })
      }
    })
  }, [targetTab])
}
